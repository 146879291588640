
<script setup lang="ts">
    import { computed } from "vue";
    import { OActionList, OActionItem } from "o365-ui-components";
    import { isMobile } from "o365.GlobalState.ts";
    import useCheckItems from "./CheckItems.Composables.useCheckItems.ts";

    import HeaderStatistics from "./CheckItems.Header.Statistics.vue";

    export interface Props {
        checkItems: ReturnType<typeof useCheckItems>;
    };

    const props = defineProps<Props>();

    const dsItemLanguages = $getDataObjectById("dsCheckItems_ItemLanguages");
</script>

<template>
    <div class="mt-2 p-1 d-flex" :class="{ 'flex-column gap-1': isMobile, 'align-items-center gap-3': !isMobile }">
        <!-- title -->
        <div class="flex-shrink-0 order-1 order-md-1">
            <span>{{ $t("Checklist") }}:</span>
            <span>&nbsp;</span>
            <span class="fw-medium">{{ checkItems.checklistName ?? $t("Loading...") }}</span>
        </div>

          <!--
                    <span class="hstack">
                        {{ $t("Checklist") }}: 
                        <span v-if="!props.idPath" class="checklist-header-text">{{ checklistName === null ? $t('Loading...') : checklistName }}</span>
                        <OChecklistsLookup v-else :bind="onChecklistSelected" :IdPath="props.idPath">                            
                            <span class="checklist-header-text">{{ checklistName === null ? $t('Loading...') : checklistName }}</span>
                            <div v-if="isSettingChecklist" class="spinner-border spinner-border-sm" role="status" style="height: 12px; width: 12px; margin-left: 0.32rem;">
                                <span class="visually-hidden">{{ $t("Loading") }}...</span>
                            </div>
                        </OChecklistsLookup>
                        <template v-if="checklistRevision !== null">, {{ $t("Revision") }}: <span class="checklist-header-text">{{ checklistRevision }}</span></template>
                        <i v-if="checklistProcedures.length > 0 && checklistName != null" @click="showChecklistProcedure" class="ms-2 bi bi-info-circle-fill text-primary" style="cursor: pointer; margin-bottom: -0.1rem;" :title="$t('Click to read checklist procedure')"></i>
                    </span>

                    <div class="ms-2 text-muted">
                        <span class="mx-2" v-if="dsCheckItems_ScopeItem.current.TotalCatAWorkflows != null">
                            PA: {{ dsCheckItems_ScopeItem.current.TotalCatAWorkflows }}
                        </span>

                        <span class="mx-2" v-if="dsCheckItems_ScopeItem.current.TotalNonCatAWorkflows != null">
                            PL: {{ dsCheckItems_ScopeItem.current.TotalNonCatAWorkflows }}
                        </span>             
                    </div>
          -->

        <!-- statistics -->
        <HeaderStatistics />

        <!-- buttons -->
        <div class="flex-shrink-0 d-flex gap-1 order-3 order-md-2">
            <OActionList>
                <template #target="{ target }">
                    <button :ref="target" class="btn btn-sm btn-outline-primary d-flex justify-content-center align-items-center gap-1 dropdown-toggle" :class="{ 'flex-1': isMobile, 'btn-sm': !isMobile }">
                        <template v-if="checkItems.isBatchUpdatingStatus">
                            <div class="spinner-border spinner-border-sm" />
                        </template>
                        <span>{{ $t("Set Check") }}</span>
                    </button>
                </template>
                <OActionItem :text="$t('Set remaining items as Checked')" @click="checkItems.batchUpdateCheckedStatus('checked')" :disabled="checkItems.isBatchUpdatingStatus" />
                <OActionItem :text="$t('Set remaining items as N/A')" @click="checkItems.batchUpdateCheckedStatus('N/A')" :disabled="checkItems.isBatchUpdatingStatus" />
                <OActionItem divider />
                <OActionItem :text="$t('Set all items as unchecked')" @click="checkItems.batchUpdateCheckedStatus('unchecked')" :disabled="checkItems.isBatchUpdatingStatus" />
            </OActionList>
            <OActionList>
                <template #target="{ target }">
                    <button :ref="target" class="btn btn-sm btn-outline-primary" :class="{ 'dropdown-toggle': dsItemLanguages.data.length >= 2, 'flex-1': isMobile, 'btn-sm': !isMobile }" :disabled="dsItemLanguages.data.length <= 1">{{ dsItemLanguages.current.Name || $t("Language") }}</button>
                </template>
                <template v-for="lang in dsItemLanguages.data">
                    <OActionItem :text="lang.Name" @click="checkItems.changeChecklistLanguage(lang.index)" />
                </template>
            </OActionList>
        </div>
    </div>
</template>
